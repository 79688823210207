@import '~@carbon/themes/scss/themes';
@import '../../mixins';
@import '../../vars';



.xts-main {
  .xts-tear {
    padding: 1rem;
  }

  .exp--tearsheet__button-container {
    .exp--action-set {
      .exp--action-set__action-button:first-child {
        flex: 1 1 auto;
        max-width: none;
      }
    }
  }
}