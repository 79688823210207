@import "~carbon-components/scss/globals/scss/mixins.scss";
@import "~@carbon/ibm-cloud-cognitive/scss/components/UserProfileImage/user-profile-image.scss";
@import '../../mixins';


.xfm-wrap {
  padding: 0;
  margin: 0;

  .xf-manage {
    display: flex;
    flex-direction: column;
    justify-items: stretch;
    align-items: stretch;
    justify-content: stretch;
    align-content: stretch;

    width: 100%;
    min-width: 100%;
    max-width: 100%;
    min-height: calc(100vh - 3rem);
    max-height: calc(100vh - 3rem);
    //height: calc(100vh - 3rem);

    overflow: scroll;
    position: relative;

    .xfm-content {
      display: flex;
      flex-direction: row;
      justify-items: stretch;
      align-items: stretch;
      justify-content: stretch;
      align-content: stretch;
      overflow-y: hidden;
      position: relative;
      flex: 1 1 auto;

      .xfm-clients {
        flex: 1 1 20%;
        min-width: 12rem;
        max-width: 18rem;
        padding: 0 !important;
        margin: 0 !important;
        box-sizing: border-box;
      }

      .xfm-projects {
        flex: 1 1 30%;
        min-width: 15rem;
        padding: 0 !important;
        margin: 0 !important;
        box-sizing: border-box;
      }

      .xfm-tests {
        flex: 1 1 50%;
        min-width: 15rem;
        padding: 0 !important;
        margin: 0 !important;
        box-sizing: border-box;
      }

    }

    .bx--structured-list {
      flex: 0 1 auto;
      margin: 0;
      overflow: scroll;

      .bx--structured-list-tbody {
        .bx--structured-list-row {
          cursor: pointer;

          .bx--structured-list-td {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;

            &:last-child {
              max-width: 2rem;

              svg {
                margin-bottom: -3px;
              }
            }
          }

          .bx--structured-list-td, .bx--structured-list-th {
            &:last-child {
              min-width: 2rem;
              width: 2rem;
              max-width: 2rem;
            }
          }
        }
      }
    }

    .xfe-add {
      .bx--text-input {
        height: auto;
        padding: 0;
        margin: 0;
        border: none;
        background: none;
        outline: none;
      }
    }

    .xfe-edit {
      height: auto;
      padding: 0;
      margin: 0;
      border: none;
      background: none;
      outline: none;
      box-sizing: border-box;

      &.bx--text-input {
        background: none;
        outline: none;
        border-bottom: 1px dashed #ccc;
      }
    }

    .bx--text-input-wrapper {

      .bx--visually-hidden {
        float: left;

        + .bx--text-input__field-outer-wrapper {
          max-height: calc(1rem - 1px);
        }
      }
    }

    .xts-sle {

    }

    .xts-slb {
      padding: 0.5rem 0 0;

      .xts-wrap {
        width: 100%;
        height: 100%;
        vertical-align: middle;
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
        justify-items: center;
      }
    }

    @include styler() {

    .xfm-clients {
      padding: 0;
      margin: 0;
      flex: 1 1 auto;
      overflow-y: auto;

      @include scrollbars($ui-01, $ui-03);

      .bx--structured-list-row {
        .bx--structured-list-td,
        .bx--structured-list-th {
          &:nth-child(2) {
            min-width: 2rem;
            width: 2rem;
            text-align: right;
          }
        }

        .xft-colour {
          padding: 0;
          height: 1rem;
          max-height: 1rem;
          width: 1rem;
          max-width: 1rem;

          .xtc-wrap {
            vertical-align: middle;
            height: 1rem;
            max-height: 1rem;
            width: 1rem;
            max-width: 1rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            align-content: center;
            justify-items: center;
          }

          .xft-col-btn {
            flex: 0 0 1rem;
            display: flex;
            margin: -3px 0 0;

            .bx--assistive-text {
              position: absolute;
            }
          }

          .xfp-pc-wrap {
            top: 1rem;
            left: 0.5rem;
          }
        }
      }
    }
  }

    .xfm-projects {
      padding: 0;
      margin: 0;

      .xfc-info {
        margin: 1rem;

        .xfc-inc {
          display: flex;
          flex-direction: row;
          margin-bottom: 1.5rem;

          .xtc-wrap {
            align-self: flex-end;
            justify-self: flex-end;
            position: relative;

            .xft-col-btn {
              padding: 0 0.5rem;
              margin: 0;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              align-content: center;
              justify-items: center;
              font-family: 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace;

              .xfc-dot {
                display: inline-block;
                border-radius: 50%;
                width: 0.75rem;
                height: 0.75rem;
                margin-right: 0.5rem;
                box-sizing: content-box;
              }

              .xfc-dot ~ .bx--btn__icon {
                margin-left: 0.5rem;
              }
            }

            .xfm-cic {
              position: absolute;
              left: 0;
              top: 2.5rem;
            }
          }
        }
      }

      .xfc-projects {
        padding: 0 0.5rem;
        margin: 1rem 0 0;
        flex: 1 1 auto;
        overflow-y: auto;
        max-height: 50%;

        @include scrollbars($ui-01, $ui-03);
      }

      .bx--structured-list-row {
        .bx--structured-list-td,
        .bx--structured-list-th {
          &:nth-child(2)  {
            min-width: 2rem;
            width: 2rem;
            text-align: right;
          }
        }
      }
      // .xfm-projects
    }

    .xfm-tests {
      padding: 0;
      margin: 0;

      .xfp-info {
        margin: 1rem;

        .xfp-inc {
          display: flex;
          flex-direction: row;
          margin-bottom: 1.5rem;

          .bx--text-input-wrapper + .bx--text-input-wrapper {
            max-width: 10rem;
            margin-left: 0.5rem;
          }

          .xtp-wrap {
            align-self: flex-end;
            justify-self: flex-end;
            position: relative;

            .xft-col-btn {
              margin: 0;
              max-width: 2rem;
              padding: 0 0.5rem;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              align-content: center;
              justify-items: center;
              font-family: 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace;

              > .bx--btn__icon {
                margin: 0;
              }
            }
          }
        }
      }

      .xfp-tests {
        padding: 0 0.5rem;
        margin: 1rem 0 0;
        flex: 1 1 auto;
        overflow-y: auto;

        @include scrollbars();
      }

      .xft-el-desc-wrap {
        white-space: pre-wrap;
      }

      .xfc-tests {
        padding: 0 0.5rem;
        margin: 1rem 0 0;
        flex: 1 1 auto;
        overflow-y: auto;

        @include scrollbars();
      }
      .bx--structured-list-row {
        .bx--structured-list-td,
        .bx--structured-list-th {
          &:nth-child(2)  {
            min-width: 2rem;
            width: 2rem;
            text-align: right;
          }
        }
      }
      // .xfm-tests
    }

  } // .xf-manage
}

@include styler() {
  .xfm-wrap .xf-manage {
    background: $ui-02;
    border: 1px solid $ui-03;

    .xft-col-btn .xfc-dot {
      border: 1px solid $inverse-02;
    }

    .bx--data-table-header {
      background: $ui-02;
      border-bottom: 1px solid $ui-03;
      display: flex;
      justify-content: space-between;
      align-content: space-between;

      .bx--data-table-header__title {
        color: $text-01;
      }
    }


    .xfl-assignee {
      .xfa-wrap {
        border: 1px dashed $ui-04;

        .xfa-info {
          .xfa-name {

          }

          .xfa-email {

          }
        }
      }
    }

    .bx--structured-list-row {
      border-bottom-color: $ui-03;
    }

    .bx--structured-list-th {
      color: $text-01;
    }

    .bx--structured-list-td {
      color: $text-02;

      .xfe-edit {
        color: $text-02;
      }
    }

    .bx--text-input, .bx--date-picker__input, .bx--list-box, .bx--dropdown {
      background: $field-01;
      color: $text-02;
    }

    .xft-tsk-detail {
      &.bx--fieldset {
        border-bottom: 1px solid $ui-03;
        margin-bottom: 1rem;
        padding-bottom: 2rem;
      }
    }

    .xft-prj-detail {
      &.bx--fieldset {

      }
    }

    .xfe-edit {
      &.bx--text-input {
        border-bottom: 1px dashed $ui-03;
      }
    }

    .xfm-clients {
      background: $ui-background;
      border-right: 1px solid $ui-03;

      .bx--structured-list-tbody {
        .bx--structured-list-row:hover, .xfc-sel {
          background: $ui-01;
        }
      }
    }

    .xfm-projects {
      background: $ui-01;
      border-right: 1px solid $ui-03;

      .xfc-info {
        .bx--text-area, .bx--text-input, .bx--list-box, .bx--dropdown, .bx--list-box__label {
          background: $field-02;
          color: $text-01;
        }
      }

      .bx--structured-list-tbody {
        .bx--structured-list-row:hover, .xfc-sel {
          background: $ui-02;
        }
      }
    }

    .xfm-tests {
      background: $ui-02;
      border-right: 1px solid $ui-03;

      .xfp-info {
        .bx--text-area, .bx--text-input, .bx--list-box, .bx--dropdown, .bx--list-box__label {
          background: $field-01;
          color: $text-02;
        }
      }

      .bx--structured-list-row {
        border-bottom-color: $decorative-01;
      }

      .bx--structured-list-tbody {
        .bx--structured-list-row:hover, .xfc-sel {
          background: $ui-01;
        }
      }
    }

  }
}

@media only screen and (max-width: 480px) {  // Mobile devices.
  main.xfm-wrap .xf-manage .xfm-content {
    .xfm-clients {
      flex: 1 1 40%;
      max-width: 100%;
    }
  }
}

@media only screen and (max-width: 768px) {
  main.xfm-wrap .xf-manage .xfm-content {
    flex-wrap: wrap;
    overflow-y: auto;

    @include scrollbars();

    .xfm-clients {
      flex: 1 1 40%;
      margin-bottom: 1.5rem;
    }

    .xfm-projects {
      flex: 1 1 60%;
      margin-bottom: 1.5rem;
    }

    .xfm-tests {
      flex: 1 1 100%;
      margin-bottom: 1.5rem;
    }

  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {  // 481px — 768px: iPads, Tablets.
  main.xfm-wrap .xf-manage .xfm-content {
    flex-wrap: wrap;
    overflow-y: auto;

    @include scrollbars();

    .xfm-clients {
      flex: 1 1 40%;

    }

    .xfm-projects {
      flex: 1 1 60%;

    }

    .xfm-tests {
      flex: 1 1 40%;

    }

  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {  // 769px — 1024px: Small screens, laptops.
  main.xfm-wrap .xf-manage .xfm-content {
    flex-wrap: wrap;
    overflow-y: auto;

    @include scrollbars();

    .xfm-clients {
      flex: 1 1 25%;
    }

    .xfm-projects {
      flex: 1 1 35%;
    }

    .xfm-tests {
      flex: 1 1 40%;
    }

  }
}

@media only screen and (max-width: 1200px) {
  .xfm-wrap .xf-manage .xfm-tasks > .xft-tasks .xfm-tasks-ac .xft-task-info .low .xfl-right .xfa-act-grp .xfa-btn-grp {
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
    justify-items: flex-end;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;

    .xfa-col-btn {
      max-width: 8rem;
    }
  }
}