@import '~@carbon/themes/scss/themes';
@import '../../mixins';
@import '../../vars';


.xf-risk-bars {
  height: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  gap: $bar-width * 0.6;

  > .xfr-bar-wrap {
    height: 2rem;
    border-radius: $bar-width / 3;
    width: $bar-width;
    min-width: $bar-width;
    max-width: $bar-width;
    flex: 1 0 auto;

    .xfb-tool {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      justify-items: flex-end;
      justify-content: flex-end;

      &::before {
        top: 0;
      }

      .bx--assistive-text {
        top: -0.3125rem;
        right: -0.2rem;
      }

      &.bx--tooltip--a11y {
        display: flex;
      }

      .xfr-bar {
        min-height: 1px;
        border-radius: $bar-width / 3;
        width: $bar-width;
        min-width: $bar-width;
        max-width: $bar-width;

        &.xfb-info {
          background: $info-color;
        }
        &.xfb-low {
          background: $low-color;
        }
        &.xfb-med {
          background: $med-color;
        }
        &.xfb-high {
          background: $high-color;
        }
        &.xfb-crit {
          background: $crit-color;
        }
      }
    }
  }
}

@include styler() {
  .xf-risk-bars {
    .xfr-bar-wrap {
      background: $ui-03;
      border: 1px dotted $ui-04;
    }
  }
}