@import '~@carbon/type/scss/styles';
@import '~@carbon/themes/scss/themes';
@import '../../mixins';
@import '../../vars';


.xfd-guide-modal {
  // enlarge the modal and thin-out the padding etc. we want it clean and easy on the eyes, not blocky and bulky
  .bx--modal-container {
    width: 85%;

    .bx--modal-content {
      padding: 1rem;
      margin-bottom: 1rem;
    }
  }
}

.xfd-guide {
  padding: 0.5rem !important;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  .xfg-left {
    flex: 1 1 50%;

  }

  .xfg-right {
    flex: 1 1 50%;
    max-width: 45rem;
  }

  .xfg-help {
    padding-right: 2rem;

    p {
      margin-top: 1rem;

      + p {
      }
    }
  }

  .xfg-hotkeys {
    display: flex;
    flex-direction: column;

    .xfh-key-wrap {
      display: flex;
      flex-direction: row;

      .xfh-keys {
        flex: 1 1 60%;
        display: flex;
        flex-direction: column;
        margin-top: 0.5rem;

        .xfh-key {
          margin-top: 0.5rem;
          display: flex;
          flex-direction: row;

          .xfk-hotkey {
            min-width: 8rem;
          }

          .xfk-action {

          }
        }

        &.basic {
          flex: 1 1 40%;

          .xfk-hotkey {
            min-width: 4.5rem;
          }
        }
      }
    }
  }
}


@include styler() {
  .xfg-help {
    p {
      @include carbon--type-style('body-long-01');
    }
  }
}
