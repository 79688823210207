@import '~@carbon/themes/scss/themes';
@import '../../mixins';
@import '../../vars';

// Carbon OverflowMenu doesn't use a carbon button internally, so doesn't have a tooltip, we need to emulate that here
.xfc-btn {
  .xfc-btn-ast {
    animation: tt-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9) !important;
    overflow: hidden !important;
    margin: -1px !important;
    clip: rect(0, 0, 0, 0) !important;
  }

  &::before {
    animation: none;
    opacity: 0 !important;
    width: 0 !important;
    height: 0 !important;
    border-style: solid !important;
    content: "" !important;
    position: absolute !important;
    z-index: 6000 !important;
    display: flex !important;
    align-items: center !important;
    pointer-events: none !important;
  }

  &:hover, &:active, &:focus-visible {
    &::before {
      opacity: 1 !important;
      animation: tt-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9) !important;
    }

    .xfc-btn-ast {
      overflow: visible !important;
      margin: auto !important;
      clip: auto !important;
    }
  }

  .xfc-btn-chk path:not([data-icon-path]):not([fill="none"]), .bx--btn__icon {
    fill: currentColor !important;
  }
}

.xfc-omo {
  position: relative;
  width: auto;
  min-width: 0;
  max-width: 6.625rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0.125rem;
  gap: 0.125rem;

  li {
    position: relative;
    min-width: 0;
    min-height: 0;
    max-width: 1.5rem;
    max-height: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;

    .xfc-btn {
      position: relative;
      padding-right: 0.2375rem;
      padding-left: 0.2375rem;
      min-width: 0;
      min-height: 0;
      max-width: 1.5rem;
      max-height: 1.5rem;
      width: 1.5rem;
      height: 1.5rem;

      .xfc-btn-inner {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;

        &.chl::after {
          bottom: -1px;
          left: -1px;
          min-width: 0;
          min-height: 0;
          content:"";
          position:absolute;
          border-top: 1px solid red;
          border-radius: 1px;
          width: 2rem;
          height: 0;
          transform: rotate(-45deg);
          transform-origin: 0 0;
        }
      }
    }
  }
}

@keyframes tt-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}