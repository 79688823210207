@import '~@carbon/themes/scss/themes';
@import '../../mixins';
@import '../../vars';


.xf-man {
  padding: 0;
  display: flex;
  flex-direction: column;

  .xfm-wrap {
    overflow: hidden;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;

    .xfm-head {
      padding: 2rem 1.5rem;

      h1 {
        font-size: 2rem;
      }
    }

    .xfm-main {
      flex: 1 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      align-content: stretch;
      position: relative;

      .xfm-left {
        justify-self: flex-start;
        position: relative;
        flex: 1 1 3rem;
        max-width: 18rem;

        .bx--side-nav__overlay {
          display: none;
        }

        .xfm-rail {
          position: relative;
          top: 0;
          left: 0;
          margin: 0;
          padding: 0;

          .bx--side-nav__items {
            margin: 0;
            padding: 0;
          }
        }
      }

      .xfm-right {
        flex: 1 1 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;

        .xfd-placeholder {
          padding: 1rem;
        }
      }
    }
  }
}


@include styler() {
  .xf-man .xfm-wrap {
    .xfm-head {
      border-bottom: 1px solid $ui-03;
      color: $text-01;
    }

    .xfm-main {
      .xfm-left {
        border-right: 1px solid $ui-03;
      }
    }
  }
}
