@import '../../../mixins';
@import '../../../vars';

.xfp-run {
  display: inline;
  position: relative;
  height: 100%;
  word-break: break-word;
  word-wrap: break-word;

  &:hover, &:active, &:focus, &:focus-visible, &:focus-within, &:visited, &:target {
    outline: none;
    border: none;
  }

  &::before {
    content: "\200b"; /* unicode zero width space character */
  }

  @include runStyles();
}
