
.xf-toaster {
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  z-index: 10000;
  max-height: calc(100vh - 1rem);
  justify-content: flex-start;

  .bx--toast-notification {
    padding-left: 1rem;
    margin: 0.5rem 0 0.5rem 0.5rem;

    :first-child {
      margin-top: 0.5rem;
    }
  }
}

