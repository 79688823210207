
.xfp-list {
  &::after {
    content: "\200b"; /* unicode zero width space character */
  }

  &.s-list {
    padding-top: 6em;
    padding-bottom: 12em;
    padding-left: 66em;

    ::marker {
      font-size: 0.9rem;
    }

    .xfp {
      vertical-align: top;

      &.s-normal {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
      }

      .xfp-run {
        vertical-align: text-top;
      }
    }
  }
}

ul.xfp-list {
  &.s-list {
    list-style-position: outside;
    list-style-type: '–    ';
  }
}

ol.xfp-list {
  &.s-list {
    list-style-position: outside;
    list-style-type: decimal;
  }
}
