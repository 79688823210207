

.xf-spell {
  padding: 0.5rem;
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-start;

  .xfs-icon {
    width: 20px;
    height: 20px;
  }

  .xfs-label {
    margin-left: 0.25rem;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}


.xf-document.fullscreen {
  .xf-spell {
    &, &:hover {
      max-width: 350px;
    }
  }
}
