@import '~@carbon/themes/scss/themes';
@import '../../mixins';
@import '../../vars';



.xts-main {
  user-select: none;
  -webkit-user-select: none;

  .xts-influence {
    padding: 1rem;
  }

  .exp--tearsheet__button-container {
    .exp--action-set {
      .exp--action-set__action-button:first-child {
        flex: 1 1 auto;
        max-width: none;
      }
    }
  }

  .exp--tearsheet__container {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    position: relative;

    .exp--tearsheet__body {
      flex: 1 1 auto;
      min-height: 0;

      &, .exp--tearsheet__right {
        &, .exp--tearsheet__main {
          &, .exp--tearsheet__content {
            position: relative;
            overflow: visible;
            // max-height: calc(100vh - 20rem);
            // max-height: 100%;
          }
        }
      }
    }
  }

  .xff-editor {
    max-height: 100%;
    overflow: visible;
    display: flex;
    flex-direction: row;

    .xff-edit {
      flex: 1 1 100%;
      overflow: visible;
    }

    .xff-tool {
      flex: 1 1 100%;
      max-width: 320px;
      overflow-x: visible;
      overflow-y: auto;

      @include scrollbars();
    }
  }
}

// composed modal version, the cloud cognitive tearsheet was causing issues
.xff-editor-wrap {
  align-items: flex-end !important;

  .xff-editor {
    width: 100%;
    height: 100%;
    max-width: calc(100% - 6rem);
    max-height: calc(100% - 3rem);
    user-select: none;
    -webkit-user-select: none;
    overflow: visible;

    .xfe-head {
      margin: 0;
    }

    .xfe-body {
      min-height: 0;
      position: relative;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      align-content: stretch;
      overflow: visible;

      .xfe-left {
        padding: 1rem;
        flex: 1 1 auto;
        max-width: 320px;
        overflow-x: visible;
        overflow-y: auto;
      }

      .xfe-main {
        min-height: 0;
        position: relative;
        flex: 1 1 100%;
        display: flex;
        flex-direction: row;
        justify-content: stretch;
        align-content: stretch;

        .xfe-doc {
          width: 100%;
          min-height: 0;
          max-height: 100%;
          flex: 1 1 auto;
          overflow: visible;

          .xf-document {

            &.fullscreen {
              position: fixed;
              top: -3rem;
              left: -3rem;
              margin: 0;
              z-index: 10;
              min-width: 100vw;
              min-height: 100vh;
            }
          }
        }
      }

      .xfe-right {
        padding: 1rem;
        flex: 1 1 100%;
        max-width: 320px;
        overflow-x: visible;
        overflow-y: auto;

        @include scrollbars();
      }
    }
  }
}


@include styler() {
  .xff-editor-wrap {
    .xff-editor {
      background-color: $ui-background;

      .xfe-head {
        background-color: $ui-01;
      }

      .xfe-body {
        .xfe-left {
          background-color: $ui-01;

          @include scrollbars($ui-03);
        }

        .xfe-main {

        }

        .xfe-right {

        }
      }
    }
  }

  .xts-main .xff-editor {

    .xff-edit {
      border-right: 1px solid $ui-03;
    }

    .xff-tool {
    }
  }
}
