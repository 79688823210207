


.xfe-edit-wrap {
  margin-top: 3rem;

  &, .xfe-doc, xfe-document {
    min-height: calc(100vh - 3rem);
    max-height: calc(100vh - 3rem);
    height: calc(100vh - 3rem);
    min-width: 100vw;
    max-width: 100vw;
    width: 100vw;
    position: relative;
  }
}
