@import '~@carbon/themes/scss/themes';
@import '../../mixins';
@import '../../vars';

@import '~carbon-components/scss/components/dropdown/dropdown';


.bx--overflow-menu-options.xft-omo {
  z-index: 9000;  // after showing then hiding the help modal these no longer work unless this is super high :(
}

.xfd-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  // z-index: 2;

  &, * {
    user-select: none;
  }

  :first-child, :last-child {
    margin-left: auto;
  }

  .xfd-grp {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &.back-btn {
      margin-left: 0;

      + .xfd-grp {
        margin-left: auto;
      }
    }

    &.xfa-drop {
      display: inline-block;


      .bx--list-box__menu {
        @include scrollbars();
      }

      &.xfd-font {
        min-width: 12rem;
      }

      &.xfd-size {
        &.xfs-combo {
          min-width: 7rem;
          max-width: 8rem;
        }
        &.xfs-fixed {
          min-width: 7rem;
          max-width: 7rem;
        }

        .bx--label {
          display: none;
        }
      }

      &.xfd-style {
        min-width: 10rem;

        .doc-style {
          .xfs-run {
            @include docStyles();

            position: relative !important;
            display: inline !important;
            margin: 0 !important;
            padding: 0 !important;
          }
        }
      }

      .bx--list-box__menu:empty {
        display: none;
      }

      .bx--dropdown, .bx--combo-box {
        background: none;

        &, .bx--list-box__field, .bx--text-input {
          border: none;
          background: none;
        }
      }

      .bx--combo-box .bx--text-input {
        padding-left: 1rem;

        &, * {
          @include textSelect();
        }
      }
    }

    .xfd-act {
      .xfa-col {
        color: red;
      }

      .xfc-btn-ast, .bx--assistive-text {
        z-index: 9000;
      }
    }
  }
}

@include styler() {
  .xfd-bar {
    background: $ui-02;
    border-bottom: 1px solid $ui-03;

    .xfd-grp {
      + .xfd-grp, &:first-child {
        border-left: 1px solid $ui-03;
      }
      &:last-child {
        border-right: 1px solid $ui-03;
      }
    }
  }
}