

.xf-zoom {
  padding: 0.5rem;
  width: 100%;
  max-width: 350px;

  .bx--slider-container {
    width: 100%;
    max-width: 100%;
    overflow: hidden;

    .bx--slider__range-label {
      margin: 0;
    }

    .bx--slider {
      flex: 0 1 auto;
      min-width: 6rem;
    }
  }
}

.xf-document.fullscreen {
  .xf-zoom {
    &, &:hover {
      max-width: 350px;
    }
  }
}

