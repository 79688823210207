

.section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #393939;
  padding: 0.5rem;
  min-height: 100%;

  + .section {
    margin-top: 0.8rem;
  }

  .page {
    position: relative;
    border: 1px solid #323233;
    background: #ffffff;
    color: #000000;
    // default size / spacing params to match word
    box-sizing: content-box;
    line-height: 1.15;
    letter-spacing: 0.01px;

    + .page {
      margin-top: 1rem;
    }

    .margin {
      position: relative;
      width: 100%;
      height: 100%;
      box-sizing: content-box;
      display: flex;
      flex-direction: column;

      justify-content: space-between;
      align-content: stretch;
      align-items: stretch;

      .pad {
        cursor: default;
        flex: 1 1 auto;
        min-width: 0;
        min-height: 0;
      }

      .footer {
        flex: 0 0 auto;
        min-width: 0;
        min-height: 0;

        &, * {
          user-select: none;
        }
      }
    }
  }

  .footer {
    opacity: 0.3;

    &:hover {
      opacity: 0.8;
    }

    .foot-split {
      margin-bottom: 1em;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: space-between;
    }
  }
}