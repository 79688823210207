@import '~@carbon/themes/scss/themes';
@import 'vars';


@mixin fullStyler($light: $carbon--theme--white, $dark: $carbon--theme--g100) {
  html {
    &.light {
      @include carbon--theme($light) {
        @content;
      }
    }

    &.dark {
      @include carbon--theme($dark) {
        @content;
      }
    }
  }
}


@mixin styler($light: $carbon--theme--white, $dark: $carbon--theme--g100) {
  $carbon--theme: $light;
  @include carbon--theme($light) {
    @content;
  }

  html.dark {
    $carbon--theme: $dark;
    @include carbon--theme($dark) {
      @content;
    }
  }
}


@mixin scrollbars($background: #151515, $foreground: #717171, $size: 12px) {
  /* Works on Firefox */
  & {
    scrollbar-width: thin;
    scrollbar-color: $foreground $background;
  }

  /* Works on Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-corner {
    background: $background;
  }

  &::-webkit-scrollbar-track {
    background: $background;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $foreground;
    border-radius: 20px;
    border: 3px solid $background;
  }
}

@mixin spanHeight() {
  &:empty::after {
    content: "\200b"; /* unicode zero width space character, forces height on empty span */
  }
}

@mixin noSelect() {
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: transparent;
}

@mixin textSelect() {
  user-select: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: -moz-text;
  -ms-user-select: text;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: transparent;
}

@mixin coreStyles() {
  &, * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
  }

  &.s-normal {
    font-weight: 400;
    font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
  }

  &.s-copy, &.s-h1, &.s-h2, &.s-h3, &.s-h4, &.s-h5, &.s-h6 {
    // IBM Plex Sans Light
    font-weight: 300;
    font-family: 'IBM Plex Sans Light', 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
  }

  &.s-h3, &.s-h4, &.s-h5, &.s-h6 {
    font-weight: 600;
    font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
  }

  &.s-copy {
    padding-bottom: 24em;
  }

  &.s-caption {
    text-align: center;
    color: #808080;
    font-style: italic;
  }

  &.s-template {
    border: 2px solid #FF0000;
    background: #FFC4C5;

    &, .xfp-run {
      white-space: pre-wrap;
      font-family: 'Courier New', monospace !important;
      caret-color: #FF0000;
    }

    &::selection {
      color: #000000 !important;
      background: #b38884 !important;
    }
  }

  &.s-finding, &.xf-risk, &.s-risk-4, &.s-risk-3, &.s-risk-2, &.s-risk-1, &.s-risk-0 {
    font-weight: 600;
  }

  &.s-code {
    white-space: pre-wrap;
    background: #D9D9D9;
    color: #000000;
    font-family: 'Courier New', monospace !important;

    &::selection {
      color: #000000 !important;
      background: #A9A9A9 !important;
    }
  }
}

@mixin runStyles() {
  @include coreStyles();

  &.xfp-link {

  }
}

@mixin docStyles() {
  @include coreStyles();

  &.xbg {
    position: absolute;
    padding: 0 !important;
  }

  &.abl {
    text-align: left;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &.abr {
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &.atr {
    text-align: right;
    top: 0;
    right: 0;
  }

  &.atl {
    text-align: left;
    top: 0;
    left: 0;
  }

  &.tal {
    text-align: left;
  }

  &.tar {
    text-align: right;
  }

  &.tac {
    text-align: center;
  }

  &.taj {
    text-align: justify;
  }

  &.s-normal {
    padding-top: 12em;
    padding-bottom: 12em;
  }

  &.s-h1, &.s-h2, &.s-h3, &.s-h4, &.s-h5, &.s-h6 {
    &, &.s-copy {
      padding-top: 12em;
      padding-bottom: 24em;
    }
  }

  &.s-h3, &.s-h4, &.s-h5, &.s-h6 {
    padding-top: 12em;
    padding-bottom: 12em;
  }

  & + .s-copy {
    padding-top: 24em;
  }

  &.s-caption {
    padding-top: 0;
    padding-bottom: 24em;
  }

  &.s-list .s-list {
    list-style-position: outside;
    list-style-type: '–    ';
    padding-top: 12em;
    padding-bottom: 24em;
    padding-left: 66em;
  }

  &.s-template {
    margin-top: 12em;
    margin-bottom: 12em;
    padding: 12em;

    + .s-template {
      border-top: none;
      margin-top: calc(-12em - 2px);
      margin-bottom: 12em;
      padding-top: calc(12em + 2px);
    }
  }

  &.s-finding, &.xf-risk, &.s-risk-4, &.s-risk-3, &.s-risk-2, &.s-risk-1, &.s-risk-0 {
    margin-top: 6em;
    margin-bottom: 20em;
  }

  &.xf-risk {
    border-top: 3px solid #000000;
  }

  &.s-risk-4 {
    border-top: 3px solid $crit-color;
  }

  &.s-risk-3 {
    border-top: 3px solid $high-color;
  }

  &.s-risk-2 {
    border-top: 3px solid $med-color;
  }

  &.s-risk-1 {
    border-top: 3px solid $low-color;
  }

  &.s-risk-0 {
    border-top: 3px solid $info-color;
  }

  &.s-code {
    padding: 1em 3em;
    margin-left: 72em;
    margin-right: 72em;
    width: calc(100% - 144em);
  }
}
