@import '../../mixins';


.xf-nav {
  flex: 1 1 auto;
  overflow-y: hidden;

  .bx--label {
    padding: 1rem 1rem 0;
    color: #8d8d8d;
  }

  .bx--tree {
    overflow-y: auto;
    max-height: calc(100% - 2.5rem);
    background: none;

    @include scrollbars();

    .bx--tree-node {
      padding-left: 1rem;
      padding-right: 1rem;
      background: none;
      color: #c6c6c6;

      .bx--tree-node__label, .bx--tree-node {
        margin-right: -1rem;
        padding-right: 1rem;
      }
    }

    .bx--tree-node--selected > .bx--tree-node__label {
      background-color: #161616;
      color: #e0e0e0;

      .bx--tree-parent-node__toggle-icon, .bx--tree-node__icon {
        fill: #c6c6c6;
      }
    }

    .bx--tree-node__label:hover {
      background-color: #161616;
      color: #c6c6c6;

      .bx--tree-node__label__details {
        color: #c6c6c6;
      }

      .bx--tree-parent-node__toggle-icon, .bx--tree-node__icon {
        fill: #c6c6c6;
      }
    }
  }
}
