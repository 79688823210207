@import '../../mixins';
@import '../../vars';


.xfp {
  position: relative;
  cursor: text;
  flex: 1 0 auto;
  box-sizing: content-box;
  max-width: 100%;
  display: inline-block;
  width: 100%;
  z-index: 1;

  br { display: none; }

  //&, p {
  //  &::after {
  //    content: "\2060"; /* unicode zero width space character */
  //  }
  //}

  &.readonly {
    &::before {
      // we pull the content from a data attribute so we can easily control it from react, for translations etc. :)
      content: attr(data-readonly-msg);
      font-size: 0.8rem;
      position: absolute;
      left: -0.5rem;
      transform: translateX(-100%);
      border: 1px dashed #8d8d8d;
      background: #e0e0e0;
      color: #161616;
      border-radius: 2px;
      padding: 0.25rem 0.5rem;
      display: none;
    }

    &.tar {
      &::before {
        left: auto;
        right: -0.5rem;
        transform: translateX(100%);
      }
    }

    &:hover, &:active, &:focus, &:focus-within, &:target {
      &::before {
        display: block;
      }
    }
  }

  &, * {

    /* Override the "CSS reset" so we inherit our em values from the document itself (needed for scaling / zoom etc.) */
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;

    //::selection { background-color: inherit !important; color: inherit !important; }
    //::-moz-selection { background-color: inherit !important; color: inherit !important; }

    ::selection { background-color: #c6c6c6; /* color: #000000; */ }
    ::-moz-selection { background-color: #c6c6c6; /*color: #000000; */ }

    .selection { background-color: #c6c6c6; /* color: #000000; */ }
  }

  &.readonly, .readonly {
    ::selection { background-color: #ba9797 !important; /* color: #000000 !important; */ }
    ::-moz-selection { background-color: #ba9797 !important; /* color: #000000 !important; */ }

    .selection { background-color: #ba9797 !important; /* color: #000000 !important; */ }

    cursor: default !important;
  }

  &.no-select, .no-select {
    user-select: none !important;
    -webkit-user-select: none !important;
    cursor: default !important;

    ::selection { background-color: transparent !important; color:inherit !important; }
    ::-moz-selection { background-color: transparent !important; color:inherit !important; }

    .selection { background-color: transparent !important; color:inherit !important; }
  }

  &:first-child {
    margin-top: 0 !important;
  }

  p {
    &, &.xfp-row {
      // why did carbon add styles to all P tags in modals, regardless of class!!!!!!
      padding-right: 0;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      letter-spacing: inherit;
    }
  }

  .xfp-row, .xfp-edit {
    word-break: break-word;
    word-wrap: break-word;
    position: relative;
    display: block;
    border: none;
    outline: none;
    box-sizing: border-box;

    &::before {
      content: "\200b"; /* unicode zero width space character */
    }
  }

  @include docStyles();

  .xf-toc-wrap {
    .xft-warn {
      position: absolute;
      max-width: 330em;
      right: 0;
      color: #686868;
      background: #ffcfcf;
      border: 1px dashed #6c6c6c;
      padding: 16em 24em;
      font-style: italic;
      cursor: help;
      transition: opacity 0.5s ease-out;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }

    .xf-toc {
      li {
        padding-top: 12em;
        padding-bottom: 12em;

        &:first-child {
          padding-top: 0;
        }
      }

      .xte-1 {
        font-weight: 600;
      }

      .xte-2 {
        font-weight: 400;
        padding-left: 24em;
      }

      .xte-3 {
        font-weight: 400;
        padding-left: 48em;
      }
    }
  }
}
