@import '../../../mixins';
@import '../../../vars';


$handle-corner: 1;
$handle-edge: 1.5;
$handle-offset: 2;
$handle-size: 2;
$handle-color: #393939;

.xfp-img {
  @include docStyles();

  position: relative;

  &, * {
    user-select: none !important;
  }

  &.xbg {
    top: 0;
    text-align: left;
    z-index: 0;
  }

  .xfi-wrap {
    display: inline-block;
    position: relative;

    .xfp-image {
      @include docStyles();

      min-width: 0;
      min-height: 0;
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      position: relative;
      z-index: 1;
    }

    .xfi-actions {
      position: absolute;
      top: -0.25rem;
      left: 100%;
      min-height: 100%;
      padding-left: 0.5rem;
      display: none;
      flex-direction: row;
      overflow: visible;
      z-index: 2;

      &:hover {
        display: flex;
      }

      > div {
        flex: 0 0 auto;
        overflow: hidden;
      }

      .xfi-acts {
        display: flex;
        flex-direction: column;

        .xfi-act {
          width: 2rem;
          height: 2rem;

          padding-right: 0.4375rem;
          padding-left: 0.4375rem;
        }
      }

      .xfi-act-wrap {
        width: 12rem;
        border: 1px solid #d9d9d9;
        background: white;
        margin-top: 0.25rem;
        margin-left: 0.5rem;
        padding: 0.5rem;
        text-align: left;
        z-index: 1;
        min-height: 0;
        align-self: flex-start;

        .bx--text-input {
          user-select: text !important;
        }

        .bx--toggle-input__label .bx--toggle__switch {
          margin-top: 0.25rem;
        }

        .bx--dropdown__wrapper {
          margin-top: 0.25rem;
        }

        .bx--list-box__menu {

          .bx--list-box__menu-item {

          }
        }
      }
    }

    &:hover {
      .xfi-actions {
        &, &:hover {
          display: flex;
        }
      }
    }

    .xfi-size {
      display: none;
    }

    &:hover {
      .xfi-size {
        &, &:hover {
          display: block;
        }
      }
    }

    .xfi-size {
      position: absolute;
      z-index: 1;

      &:hover {
        display: block;
      }

      &.corner {
        width: #{$handle-corner}rem;
        height: #{$handle-corner}rem;
      }

      &.edge {
        width: #{$handle-edge}rem;
        height: #{$handle-edge}rem;
      }

      // corners
      &.xfs-tl {
        cursor: nw-resize;
        top: -#{$handle-size + $handle-offset}px;
        left: -#{$handle-size + $handle-offset}px;
        border-top: #{$handle-size}px solid $handle-color;
        border-left: #{$handle-size}px solid $handle-color;
      }
      &.xfs-tr {
        cursor: ne-resize;
        top: -#{$handle-size + $handle-offset}px;
        right: -#{$handle-size + $handle-offset}px;
        border-top: #{$handle-size}px solid $handle-color;
        border-right: #{$handle-size}px solid $handle-color;
      }
      &.xfs-bl {
        cursor: sw-resize;
        bottom: -#{$handle-size + $handle-offset}px;
        left: -#{$handle-size + $handle-offset}px;
        border-bottom: #{$handle-size}px solid $handle-color;
        border-left: #{$handle-size}px solid $handle-color;
      }
      &.xfs-br {
        cursor: se-resize;
        bottom: -#{$handle-size + $handle-offset}px;
        right: -#{$handle-size + $handle-offset}px;
        border-bottom: #{$handle-size}px solid $handle-color;
        border-right: #{$handle-size}px solid $handle-color;
      }

      // edges
      &.xfs-tm {
        cursor: n-resize;
        left: calc(50% - #{($handle-edge / 2)}rem);
        top: -#{$handle-size + $handle-offset}px;
        border-top: #{$handle-size}px solid $handle-color;
      }
      &.xfs-rm {
        cursor: e-resize;
        top: calc(50% - #{($handle-edge / 2)}rem);
        right: -#{$handle-size + $handle-offset}px;
        border-right: #{$handle-size}px solid $handle-color;
      }
      &.xfs-bm {
        cursor: s-resize;
        left: calc(50% - #{($handle-edge / 2)}rem);
        bottom: -#{$handle-size + $handle-offset}px;
        border-bottom: #{$handle-size}px solid $handle-color;
      }
      &.xfs-lm {
        cursor: w-resize;
        top: calc(50% - #{($handle-edge / 2)}rem);
        left: -#{$handle-size + $handle-offset}px;
        border-left: #{$handle-size}px solid $handle-color;
      }
    }
  }
}
