@import '~@carbon/themes/scss/themes';
@import '../../mixins';
@import '../../vars';

$carbon--theme: $carbon--theme--g100;
@include carbon--theme($carbon--theme--g100);


.xf-document {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;

  * {
    margin: 0;
    padding: 0;
    @include textSelect();
  }

  .xfd-wrap {
    flex: 1 1 auto;
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: 100%;
    min-width: 0;
    min-height: 0; /* overflow fix, leave this in */
    max-width: 100%;
    max-height: 100%;

    .xfd-zoom {
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 180px;
      background: rgba(22, 22, 22, 0.6);
      border-top-right-radius: 3px;
      padding: 0.25rem 0.5rem 0;
      transition: background-color ease-in .2s, max-width ease-in .2s;
      z-index: 1;

      * {
        @include noSelect();
      }

      &:hover, &:focus, &:active, &:target, &:focus-within {
        background: rgba(22, 22, 22, 0.95);
        max-width: 280px;
      }

      .bx--label {
        color: #c6c6c6;
      }

      .bx--slider__range-label {
        &:first-of-type {
          padding-right: 0.25rem;
        }
        &:last-of-type {
          padding-left: 0.25rem;
        }

        color: #c6c6c6;
      }

      .bx--slider__track {
        background-color: #393939;
      }

      .bx--slider__thumb {
        background-color: #6f6f6f;
      }

      .bx--slider__filled-track {
        background-color: #8d8d8d;
      }
    }

    .xfd-spell {
      position: absolute;
      bottom: 0;
      right: 0;
      max-width: 180px;
      background: rgba(22, 22, 22, 0.6);
      border-top-left-radius: 3px;
      padding: 0.25rem 0.5rem 0;
      transition: background-color ease-in .2s, max-width ease-in .2s;
      color: #8d8d8d;
      z-index: 1;

      * {
        @include noSelect();
      }

      .bx--label {
        color: #8d8d8d;
      }

      &:hover, &:focus, &:active, &:target, &:focus-within {
        background: rgba(22, 22, 22, 0.95);
        max-width: 280px;

        .bx--label {
          color: #c6c6c6;
        }
      }
    }

    .xfd-nav-wrap {
      flex: 0 1 auto;
      max-width: 350px;
      min-width: 280px;
      padding: 0;
      max-height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      user-select: none;
      background: #262626;
      border-right: 1px solid #161616;

      .xfd-nav {
        flex: 1 1 auto;
        overflow-y: hidden;

        .bx--label {
          padding: 1rem 1rem 0;
        }

        .bx--tree {
          overflow-y: auto;
          max-height: calc(100% - 2.5rem);

          @include scrollbars($ui-01, $ui-02);

          .bx--tree-node {
            padding-left: 1rem;
            padding-right: 1rem;

            .bx--tree-node__label, .bx--tree-node {
              margin-right: -1rem;
              padding-right: 1rem;
            }
          }
        }
      }

      .xfd-zoom {
        position: relative;
        flex: 0 1 auto;
        align-self: center;
        justify-self: flex-end;
        min-width: 100%;
        max-width: 100%;
        width: 100%;
        opacity: 1;
        background: #161616;

        .bx--label {
          color: #8d8d8d;
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
          border-radius: 0;
        }

        .bx--label, .bx--slider__range-label {
          color: #8d8d8d;
        }

        &:hover {
          .bx--label, .bx--slider__range-label {
            color: #c6c6c6;
          }
        }
      }
    }

    .preview {
      flex: 1 1 auto;
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;

      position: relative;
      overflow-x: visible;
      overflow-y: auto;
      user-select: contain; // really annoying that browsers don't support this, firefox even removed support...

      // caret-color: transparent;

      @include scrollbars();
    }

    .loading-container {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.8);
      z-index: 3;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding-top: 100px;
    }
  }
}

#xfc-focus {
  min-width: 1px;
  max-width: 1px;
  width: 1px;

  background: black;
  animation: 1s blink step-end infinite;


  &::after {
    content: "\2060"; /* unicode zero width space character */
  }
}

@keyframes blink {
  from, to {
    background-color: black;
  }
  50% {
    background-color: white;
  }
}
