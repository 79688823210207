@import "~carbon-components/scss/globals/scss/mixins.scss";
@import "~@carbon/ibm-cloud-cognitive/scss/components/UserProfileImage/user-profile-image.scss";
@import "~@carbon/themes/scss/themes";
@import "~@carbon/charts/styles/styles.scss";
@import '../../mixins';
@import '../../vars';

.xf-budget {
  padding: 0;
  display: flex;
  flex-direction: column;

  .xfm-wrap {
    overflow: hidden;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;

    .xfm-head {
      padding: 2rem 1.5rem;

      h1 {
        font-size: 2rem;
      }
    }

    .xfm-main {
      flex: 1 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      align-content: stretch;
      position: relative;

    }
  }
}
