
.xfp-table {
  margin-top: 12em;
  margin-bottom: 24em;
  text-align: left;
  font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
  outline: none !important;

  thead {
    background: #000000;
    color: #ffffff;
    outline: none !important;

    .xft-col-btn {
      padding: calc(0.375rem - 3px) 0.5rem;
    }

    .xft-col-act {
      position: absolute;
      bottom: 100%;
      width: calc(100% - 18em);
      padding-bottom: 1px;
      align-content: center;
      justify-content: center;
      display: none;
      z-index: 2;

      &, * {
        user-select: none;
      }

      &:hover {
        display: flex;
      }
    }

    th:hover {
      .xft-col-act {
        display: flex;
      }
    }

    th {
      .xft-col-act:hover {
        display: flex;
      }
    }

    .xft-col-size {
      position: absolute;
      top: 0;
      right: -0.4rem;
      height: 100%;
      z-index: 1;

      &, * {
        user-select: none;
      }

      .xft-cs-handle {
        display: block;
        height: 100%;
        width: 0.8rem;
        cursor: col-resize;
      }
    }

    th:last-child {
      .xft-col-size {
        right: -1.4rem;
        .xft-cs-handle {
          width: 1.8rem;
        }
      }
    }
  }

  th, td {
    position: relative;
    border: 1px solid #4472C4;
    padding: 12em 9em;
    outline: none !important;
    overflow: visible;

    .xfp {
      &.s-normal {
        padding: 0;
      }

      &::after {
        display: none;
      }
    }
  }

  td {
    padding: 9em;
  }
}
