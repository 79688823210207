@import '~@carbon/themes/scss/themes';
@import '../../mixins';
@import '../../vars';

// Carbon OverflowMenu doesn't use a carbon button internally, so doesn't have a tooltip, we need to emulate that here
.xfb-corner {
  position: relative;

  &::after {
    content: " " !important;
    opacity: 1 !important;
    box-shadow: none !important;
    display: block !important;
    position: absolute !important;
    margin: 0 !important;
    padding: 0 !important;
    right: 0 !important;
    bottom: -2px !important;
    top: initial !important;
    left: initial !important;
    min-width: 0 !important;
    min-height: 0 !important;
    max-width: 0 !important;
    max-height: 0 !important;
    width: 0 !important;
    height: 0 !important;
    background: none !important;
    border-radius: 0 !important;
    border: none !important;
    border-top: 4px solid transparent !important;
    border-bottom: 4px solid transparent !important;
    border-left: 4px solid black !important;

    transform-origin: center center;
    transform: rotate(45deg) !important;
  }
}

.xfb-tt-btn {
  .xfb-tt-btn-ast {
    animation: tt-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9) !important;
    overflow: hidden !important;
    margin: -1px !important;
    clip: rect(0, 0, 0, 0) !important;
  }

  &::before {
    animation: none;
    opacity: 0 !important;
    width: 0 !important;
    height: 0 !important;
    border-style: solid !important;
    content: "" !important;
    position: absolute !important;
    z-index: 6000 !important;
    display: flex !important;
    align-items: center !important;
    pointer-events: none !important;
  }

  &:hover, &:active, &:focus-visible {
    &::before {
      opacity: 1 !important;
      animation: tt-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9) !important;
    }

    .xfb-tt-btn-ast {
      overflow: visible !important;
      margin: auto !important;
      clip: auto !important;
    }
  }
}

.xfd-mnb-wrap {
  position: relative;

  .xfd-bar-btn-box {
    position: absolute;
    top: 100%;
    left: -1px;
    z-index: 1;
    display: flex;
    flex-direction: row;
  }
}

.xfb-omo {
  width: auto;
  min-width: 10rem;
  z-index: 9000; // carbon modals break z-index even after they're gone, unless its super high

  .bx--overflow-menu-options__btn {
    max-width: 18rem;
  }
}

.xfb-but-omo {
  width: auto;
  min-width: 0;
  display: flex;
  flex-direction: row;
  z-index: 9000; // carbon modals break z-index even after they're gone, unless its super high
}

@keyframes tt-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@include styler() {
  .xfd-mnb-wrap {
    .xfd-bar-btn-box {
      background: $ui-background;
      border: 1px solid $ui-01;
    }
  }
}