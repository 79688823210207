@import '~@carbon/themes/scss/themes';
@import '../../mixins';
@import '../../vars';

// Carbon OverflowMenu doesn't use a carbon button internally, so doesn't have a tooltip, we need to emulate that here
.xfb-attach {
  position: relative;

  .xfb-atc-btn {

  }

  .xfb-atc-drop {
    position: absolute;
    top: 100%;
    right: -1px;
    width: 30rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    .xfb-adi-link {
      flex: 1 0 auto;
      min-width: 27.8rem;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);

      .xfb-adi-link-input {
        user-select: text;
        padding: 0.25rem 0.5rem;
      }
    }

    .xfb-adi-act {
      flex: 0 1 auto;
      display: flex;
      justify-content: flex-end;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);

      .xfb-adi-btn {
        padding: 0.25rem 0.5rem;
      }
    }

    .xfb-adi-file {
      padding: 0.25rem 0.5rem 0.5rem;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
      width: 20rem;

      .bx--label-description {
        margin-bottom: 0.5rem;
      }

      .bx--file__drop-container {
        padding: 0.25rem;
      }
    }
  }
}

@include styler() {
  .xfb-attach {
    .xfb-atc-btn {

    }

    .xfb-atc-drop {

      .xfb-adi-link {
        .xfb-adi-link-input {
          background-color: $ui-01;
          border-bottom-color: $ui-03;
        }
      }

      .xfb-adi-act {
        background-color: $ui-01;

        .xfb-adi-btn {

        }
      }

      .xfb-adi-file {
        background-color: $ui-01;
      }
    }
  }
}