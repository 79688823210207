@import '~@carbon/themes/scss/themes';
@import '../../mixins';
@import '../../vars';

// Carbon OverflowMenu doesn't use a carbon button internally, so doesn't have a tooltip, we need to emulate that here
.xft-btn {
  .xft-btn-ast {
    animation: tt-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9) !important;
    overflow: hidden !important;
    margin: -1px !important;
    clip: rect(0, 0, 0, 0) !important;
  }

  &::before {
    animation: none;
    opacity: 0 !important;
    width: 0 !important;
    height: 0 !important;
    border-style: solid !important;
    content: "" !important;
    position: absolute !important;
    z-index: 6000 !important;
    display: flex !important;
    align-items: center !important;
    pointer-events: none !important;
  }

  &:hover, &:active, &:focus-visible {
    &::before {
      opacity: 1 !important;
      animation: tt-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9) !important;
    }

    .xft-btn-ast {
      overflow: visible !important;
      margin: auto !important;
      clip: auto !important;
    }
  }

  .xft-btn-chk path:not([data-icon-path]):not([fill="none"]), .bx--btn__icon {
    fill: currentColor !important;
  }
}

.xft-omo {
  position: relative;
  width: auto;
  min-width: 0;
  max-width: 6.625rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0.125rem;
  gap: 0.125rem;

  li {
    position: relative;
    min-width: 0;
    min-height: 0;
    max-width: 1rem;
    max-height: 1rem;
    width: 1rem;
    height: 1rem;

    .xft-btn {
      position: relative;
      min-width: 1rem;
      min-height: 1rem;
      max-width: 1rem;
      max-height: 1rem;
      width: 1rem;
      height: 1rem;
      padding: 0;

      .xft-btn-inner {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        min-width: 1rem;
        min-height: 1rem;
        max-width: 1rem;
        max-height: 1rem;
        width: 1rem;
        height: 1rem;
      }
    }
  }
}

@include styler() {
  .xft-omo {
    .xft-btn {
      .xft-btn-inner {
        background: $ui-01;
        border: 1px solid #d4bbff;

        &.selected {
          background: $highlight;
        }
      }
    }
  }
}

@keyframes tt-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}