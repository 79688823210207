@import '~@carbon/themes/scss/themes';
@import 'mixins';


/// Sets the prefix for all classes
/// @access public
/// @type String
/// @example .bx--batch-actions
$prefix: 'bx' !default;

@include styler() {
  html, body, main, main.bx--content, .bx--content {
    background-color: $ui-background;
    color: $text-01;
  }
}

html, body {
  overflow: hidden;
}

:root {
  color-scheme: dark;
}

// @import '~carbon-components/scss/globals/scss/styles.scss';
