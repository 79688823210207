@import '~@carbon/themes/scss/themes';
@import '../../mixins';
@import '../../vars';


.xf-ft-wrap {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  align-content: stretch;
  justify-content: stretch;
  align-self: stretch;
  justify-self: stretch;

  .xft-left {
    flex: 1 1 auto;

    .bx--data-table-container {
      padding: 0;
      margin: 0;
    }

    .xf-rsk-dot {
      &.xfd-info svg {
        fill: $info-color;
      }
      &.xfd-low svg {
        fill: $low-color;
      }
      &.xfd-med svg {
        fill: $med-color;
      }
      &.xfd-high svg {
        fill: $high-color;
      }
      &.xfd-crit svg {
        fill: $crit-color;
      }
    }

    .xfc-base_risk, .xfc-contextual_risk {
      button {
        padding: 0 1rem;
      }
    }

    .xf-act-wrap {
      width: 1rem;
      padding: 0.25rem;

      .xf-actions {
        display: flex;
        flex-direction: row;

        .xff-act {
          width: 2rem;
          height: 2rem;
          padding-right: 0.4375rem;
          padding-left: 0.4375rem;
        }
      }
    }
  }

  .xft-right {
    flex: 1 1 auto;
  }
}


@include styler() {
  .xf-ft-wrap {
    .xft-left {
      border-right: 1px solid $ui-03;
    }
  }
}

