@import '~@carbon/themes/scss/themes';
@import '../../mixins';
@import '../../vars';


.xf-main {
  padding: 0;
  display: flex;
  flex-direction: column;

  .xft-wrap {
    overflow: hidden;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;

    .xft-head {
      padding: 2rem 1.5rem;

      h1 {
        font-size: 2rem;
      }
    }

    .xft-list {
      flex: 1 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      align-content: stretch;

      .xft-ls-filter {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        max-width: 18rem;
        padding: 0.5rem;
        overflow-y: auto;

        @include scrollbars();
      }

      .xft-ls-items {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;

        @include scrollbars();

        .xft-ls-head {
          padding: 1rem;
        }

        .xft-ls-tbl {
          .xfh-findings, .xfc-findings {
            width: 8rem;
          }

          .xf-risk-head {
            max-width: $bar-width * 22;
            width: $bar-width * 22;

            .bx--table-header-label {

            }
          }

          .xf-risk-cell {
            max-width: $bar-width * 22;
            width: $bar-width * 22;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-content: space-between;
            align-items: center;
            justify-items: center;
          }

          .xf-act-wrap {
            width: 1rem;
            padding: 0.25rem;

            .xf-actions {
              display: flex;
              flex-direction: column;
            }
          }

          .xff-ls {
            list-style: none outside none;

            .xff-itm {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-content: space-between;
              align-items: center;
              justify-items: center;
              box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
              border: 1px dashed #ccc;

              & + .xff-itm {
                margin-top: 0.25rem;
              }

              h4 {
                font-size: 0.875rem;
                line-height: 1.25;
                letter-spacing: 0.16px;
                font-weight: 400;
                flex: 1 1 auto;
                padding: 0.25rem 0.5rem;
              }

              .bx--label {
                padding: 0.25rem 0.5rem;
                margin: 0;
              }

              .xfi-risk-lbl {
                display: flex;
                flex-direction: row;
                width: 20rem;

                .bx--label {
                  flex: 1 1 auto;
                  display: flex;
                  flex-direction: column;

                  &.xfr-base {
                    width: 8rem;
                  }

                  &.xfr-ctx {
                    width: 12rem;
                  }
                }
              }

              .xfi-syn-lbl {
                width: 50%;

                .bx--assistive-text {

                }

                .xfi-sol-lbl {

                }
              }

              &.xfi-info {
                border-left: 3px solid $info-color;
              }
              &.xfi-low {
                border-left: 3px solid $low-color;
              }
              &.xfi-med {
                border-left: 3px solid $med-color;
              }
              &.xfi-high {
                border-left: 3px solid $high-color;
              }
              &.xfi-crit {
                border-left: 3px solid $crit-color;
              }
            }
          }
        }
      }
    }
  }
}


@include styler() {
  .xf-main .xft-wrap {
    .xft-head {
      border-bottom: 1px solid $ui-03;
      color: $text-01;
    }

    .xft-list {
      .xft-ls-filter {
        border-right: 1px solid $ui-03;
      }

      .xft-ls-items {
        .xft-ls-tbl {
          .xff-ls .xff-itm {
            border-color: $ui-04;
          }

          .xf-actions {
            .xff-act {
              .bx--btn__icon, .bx--btn__icon path:not([data-icon-path]):not([fill=none]) {
                fill: $ui-04;
              }
            }
          }
        }

        .xfi-syn-lbl {
          .xfi-sol-lbl {
            strong {
              color: $text-03;
            }
            color: $text-04;
          }
        }
      }
    }
  }
}
