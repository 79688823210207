

.xf-main-header {
  & ~ main {
    min-height: calc(100vh - 3rem);
  }

  .xf-progress {
    content: ' ';
    position: absolute;
    bottom: -1px;
    left: 0;
    z-index: -1;
    border: 1px solid #8c101c; // red-70
    border-radius: 1px;
  }

  .header-xf-icon {
    fill: #f4f4f4;
    margin-left: 1rem;
    margin-right: -0.5rem;
  }

  .xf-profile-pic, .xf-nav-ico {
    display: inline-flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    color: #f4f4f4;
    fill: #f4f4f4;
    background: none;

    &:hover {
      background: #393939;
    }

    img {
      max-width: 32px;
      border-radius: 50%;
    }
  }

  .xf-side-panel {
    display: flex;
    flex-direction: column;

    > div {
      width: 254px;
    }

    .xf-hp-inner {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .xf-switcher {
        margin-top: 1rem;
      }

      .xf-lights {
        padding: 1rem;
        flex: 1 1 auto;
        display: flex;

        .xfl-tog {
          justify-self: flex-end;
          align-self: flex-end;
        }
      }
    }

    .xf-user-info {
      display: flex;
      flex-direction: column;
      font-size: 0.875rem;
      line-height: 1.125rem;
      letter-spacing: 0.16px;
      text-decoration: none;
      color: #c6c6c6;

      .xui-head {
        font-weight: 600;
        padding: 0.375rem 1rem;
      }

      .xui-sub {
        padding: 0.1rem 1.5rem;
      }
    }
  }

  .bx--header__name--prefix {
    margin-right: 0;
  }

  .xf-divider {
    margin-right: 3px;
    font-weight: 400;
  }

  .header-xf-icon {
    fill: #f4f4f4;
    color: #f4f4f4;
    margin-left: 1rem;
    margin-right: -0.5rem;

    svg, path {
      fill: #f4f4f4;
      color: #f4f4f4;
    }
  }
}